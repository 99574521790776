<template>
  <div>
    <TheNavbar />
    <section class="columns is-fullheight">
      <div class="column is-2 has-background-primary">
        <img
          :src="
            'https://ui-avatars.com/api/?rounded=true&size=240&name=' +
            GetNameInitials[0] +
            '+' +
            GetNameInitials[1] +
            '&background=0D8ABC&color=fff'
          "
        />
        <div class="container">
          <p class="has-text-weight-bold has-text-white is-size-5">
            {{ GetCurrentUser.name }}
          </p>
        </div>
        <br />
        <section class="section">
          <div class="columns is-multiline is-centered is-vcentered">
            <div
              class="column has-vertical-gap-bottom has-rounded-corner is-full has-background-info-light"
            >
              <span
                class="has-text-weight-bold"
                v-if="FilteredAlertsCount > 0 && FilteredAlertsCount < 30"
                >{{ FilteredAlerts.length }} imagens disponíveis</span
              >
              <!-- <span class="has-text-weight-bold" v-if="FilteredAlertsCount > 0 && FilteredAlertsCount < 30">{{ FilteredAlerts.length }} imagens disponíveis</span> -->
              <span
                class="has-text-weight-bold"
                v-else-if="FilteredAlertsCount > 29"
                >{{ FilteredAlerts.length }}+ imagens disponíveis</span
              >
              <span class="has-text-weight-bold" v-else
                >Sem imagens disponíveis</span
              >
            </div>
            <div
              @click="
                ToggleWorking();
                GetAlertToWorkWith();
              "
              class="has-cursor-pointer column has-vertical-gap-bottom has-rounded-corner is-full"
              v-bind:class="{
                'has-background-danger': IsCurrentlyWorking,
                'has-background-success': !IsCurrentlyWorking,
              }"
            >
              <span class="has-text-weight-bold" v-if="IsCurrentlyWorking">
                Parar de avaliar
              </span>
              <span class="has-text-weight-bold" v-else>
                Começar a avaliar
              </span>
            </div>
          </div>
        </section>
      </div>
      <div
        class="column"
        v-if="IsCurrentlyWorking && FilteredAlertsCount > 0"
      ></div>
      <div
        class="column is-parent-full-height is-flex is-align-items-center is-justify-content-center"
        v-bind:class="{
          'has-background-info': !IsCurrentlyWorking,
          'has-background-warning':
            IsCurrentlyWorking && FilteredAlertsCount <= 0,
          'is-8': IsCurrentlyWorking && FilteredAlertsCount > 0,
        }"
      >
        <div
          class="columns is-multiline"
          v-if="IsCurrentlyWorking && FilteredAlertsCount > 0"
        >
          <div class="column is-four-fifths box mb-2">
            <img
              :src="ImageDownloadLink"
              v-if="ImageDownloadLink"
              class="imgSize mt-4"
            />
            <div v-else>
              <b-loading
                :is-full-page="false"
                v-model="RetrievingImageDownloadLink"
                :can-cancel="false"
              ></b-loading>
              <p class="title">Requisitando imagem...</p>
            </div>
          </div>
          <div
            class="column is-four-fifths box has-text-weight-bold has-text-centered bar mb-2"
          >
            <b-field>
              <div>
                <b-radio v-model="WarningApproval" :native-value="false">
                  Reprovar
                </b-radio>
                <b-radio v-model="WarningApproval" :native-value="true">
                  Aprovar
                </b-radio>
              </div>
            </b-field>
            <b-field
              grouped
              v-if="OperationHasSoundAlert && WarningApproval"
              position="is-centered"
            >
              <b-field>
                <b-switch
                  v-model="soundAlert"
                  type="is-success"
                  passive-type="is-danger"
                  outlined
                  left-label
                >
                  {{ $t("sauronAux.soundAlert") }}
                </b-switch>
              </b-field>
            </b-field>
            <!-- Field for entering observations. -->
            <div v-if="false">
              <!-- <div> -->
              <b-field label-position="on-border" type="is-info">
                <template slot="label">
                  <span class="has-text-primary is-family-sans-serif"
                    >Observações</span
                  >
                </template>
                <b-input
                  type="textarea"
                  v-model="CurrentImageObject.details"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <!-- Field for marking a message has been sent. -->
            <div v-if="false">
              <!-- <div v-if="WarningApproval"> -->
              <b-field>
                <b-button
                  @click="sendMessage"
                  label="Gerar mensagem de WhatsApp"
                  type="is-success"
                  outlined
                ></b-button>
              </b-field>
              <p>Notificação enviada?</p>
              <b-radio
                v-model="CurrentImageObject.notification"
                :native-value="true"
              >
                Sim
              </b-radio>
              <b-radio
                v-model="CurrentImageObject.notification"
                :native-value="false"
              >
                Não
              </b-radio>
            </div>
            <!-- -------------------------------------- -->
            <b-field>
              <b-button
                @click="InsertClassifiedImage"
                :disabled="IsConfirmButtonDisabled"
                :loading="IsConfirmButtonLoading"
                label="Confirmar avaliação"
                type="is-primary"
                outlined
              ></b-button>
            </b-field>
          </div>
        </div>
        <div
          class="column is-parent-full-width"
          v-else-if="IsCurrentlyWorking && FilteredAlertsCount <= 0"
        >
          <div class="container">
            <h1 class="title has-text-info">
              Sem alertas na fila. Aguarde a chegada de novos alertas.
            </h1>
          </div>
        </div>
        <div class="column is-parent-full-width" v-else>
          <div class="container">
            <h1 class="title has-text-white">
              Aperte em começar "Começar a avaliar" para ver as imagens.
            </h1>
          </div>
        </div>
      </div>
    </section>
    <b-modal v-model="messageModal" has-modal-card>
      <div class="card">
        <b-image :src="alertSauron.image" />
        <br />
        <SauronAlertGenerator :SauronAlertObject="alertSauron" />
        <br />
        <b-button
          type="is-danger"
          expanded
          outlined
          @click="messageModal = !messageModal"
          style=""
          >Fechar</b-button
        >
        <br />
      </div>
    </b-modal>
  </div>
</template>
<style lang="scss">
.bar {
  height: 25%;
}
.imgSize {
  // height: auto;
  // width: 100vh;
  height: calc(80.6vh - 3.25rem);
}
.is-fullheight.columns {
  margin: 0 auto;
  height: calc(99.6vh - 3.25rem);
  max-height: 100%;
}

.is-parent-full-height {
  height: 100%;
}

.is-parent-full-width {
  width: 100%;
}

.column.has-vertical-gap-bottom {
  margin-bottom: 1vh;
}

.column.has-rounded-corner {
  border-radius: 12px;
}

.has-cursor-pointer {
  cursor: pointer;
}
</style>
<script>
import firebase from "../../../firebase.config.js";

import SauronAlertGenerator from "@/components/sauron/SauronAlertGenerator.vue";

import TheNavbar from "@/components/global/TheNavbar.vue";
import SauronTemplateModal from "@/components/sauron/SauronTemplateModal.vue";
export default {
  data() {
    return {
      IsCurrentlyWorking: false,
      IsConfirmButtonLoading: false,
      IsConfirmButtonDisabled: true,
      FilteredAlerts: [],
      FilteredAlertsCount: 1,
      ImageDownloadLink: "",
      WarningApproval: false,
      CurrentImageObject: null,
      soundAlert: true,
      ClassifiedImageObject: {
        test: null,
      },
      TemplateImageDownloadLinkDay: "",
      TemplateImageDownloadLinkNight: "",
      isImageModalActive: false,
      messageModal: null,
      alertSauron: {
        locale: " ",
        latitude: " ",
        longitude: " ",
        date: " ",
        hours: " ",
        camera: " ",
        preset: " ",
        position: " ",
        operator: " ",
        details: " ",
        image: " ",
        notification: false,
      },
    };
  },
  components: {
    SauronAlertGenerator,
    TheNavbar,
    SauronTemplateModal, // eslint-disable-line
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetAvailableImages() {
      return this.$store.getters["sauron/GetAvailableImages"].filter(
        (object) => {
          return object[".key"].split("-")[2] > 1608471525000;
        }
      ); //1608241573000
    },
    GetImageDownloadLink() {
      return this.ImageDownloadLink;
    },
    GetNameInitials() {
      return this.GetCurrentUser.email.split("@")[0].split(".");
    },
    OperationHasSoundAlert() {
      const operationsWithSoundAlert = [
        "sabesp",
        "sabesp_cacapava",
        "sabesp_guararema",
        "puerta_de_oro",
      ];
      return operationsWithSoundAlert.includes(
        this.GetCurrentUser.activeFlag.toLowerCase()
      );
    },
  },
  watch: {
    GetCurrentUser: {
      handler() {
        this.$store.dispatch("sauron/unbindAvailableImages").then(() => {
          this.$store.dispatch(
            "sauron/bindAvailableImages",
            this.GetCurrentUser
          );
        });
      },
      deep: true,
    },
    GetAvailableImages: {
      handler(images, oldImages) {
        this.FilteredAlerts = images;
        this.FilteredAlertsCount = this.FilteredAlerts.length;
        if (images.length > 0 && oldImages.length == 0) {
          this.GetAlertToWorkWith();
        }
      },
    },
  },
  methods: {
    // function to select data sent to the variable SauronAlertObject of the SauronAlertGenerator component
    sendMessage() {
      this.messageModal = !this.messageModal;
      this.alertSauron.image = this.ImageDownloadLink;
      this.alertSauron.operator = this.GetCurrentUser.name;
      this.alertSauron.date = new Date(
        this.CurrentImageObject.datetime
      ).toLocaleDateString("pt-BR");
      this.alertSauron.hours = new Date(
        this.CurrentImageObject.datetime
      ).toLocaleTimeString("pt-BR");
      this.alertSauron.latitude = this.CurrentImageObject.lla.latitude;
      this.alertSauron.longitude = this.CurrentImageObject.lla.longitude;
      this.alertSauron.preset = this.CurrentImageObject.ptz.presetID;
      this.alertSauron.camera = this.CurrentImageObject.tower;
      this.alertSauron.details = this.CurrentImageObject.details;
    },

    getAlertDatetime() {
      // sometimes datetime in alert key is diferrent from datetime inside the alert object
      const alertKeyDatetime = this.CurrentImageObject[".key"].split("-").pop();
      const alertDatetime = this.CurrentImageObject.datetime;
      if (alertKeyDatetime === alertDatetime) {
        return alertDatetime;
      }
      return alertKeyDatetime;
    },

    InsertClassifiedImage() {
      this.IsConfirmButtonLoading = true;

      this.CurrentImageObject.operator = this.GetCurrentUser.name;
      let warningFlag = this.CurrentImageObject.idxname.split("-")[0];
      let warningDate = new Date(this.CurrentImageObject.datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      let warningKey = this.CurrentImageObject[".key"];
      let warningCam = this.CurrentImageObject.camera;
      let databasePathRemove =
        "sauron/" + warningFlag + "-backlog/" + warningKey;
      let currentDateString = new Date(this.CurrentImageObject.datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");

      let databasePathCount;
      let databasePath;
      const newMordorTenants = [
        "transpetro",
        "sabesp_cacapava",
        "sabesp_guararema",
        "sabesp",
        "puerta_de_oro",
      ];
      const alertDatetime = this.getAlertDatetime();
      if (
        newMordorTenants.includes(this.GetCurrentUser.activeFlag.toLowerCase())
      ) {
        databasePathCount = `sauron/${warningFlag}/${this.CurrentImageObject.camera}/count/${warningDate}`;
        databasePath = `${warningFlag}/${this.CurrentImageObject.camera}/${currentDateString}/sauron-ACCC8ED12FB7-${alertDatetime}`;
      } else {
        databasePathCount = `sauron/${warningFlag}/count/${warningDate}`;
        databasePath = `${warningFlag}/${currentDateString}/sauron-ACCC8ED12FB7-${alertDatetime}`;
      }

      if (this.WarningApproval) {
        return this.$store
          .dispatch("database/PushWarningToDatabase", {
            path: databasePath,
            warningObject: this.CurrentImageObject,
          })
          .then(() => {
            this.IsConfirmButtonSpinning = false;
          })
          .then(() => {
            //Remove it from the backlog.
            return this.$store.dispatch("database/RemoveFromDatabase", {
              path: databasePathRemove,
            });
          })
          .then(() => {
            //Set a copy of it on a list of all human-approved alerts.
            this.CurrentImageObject.labeledImage = warningKey;
            return this.$store.dispatch("database/SetOnDatabase", {
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "-human-approved/" +
                warningKey.split("-").slice(0, 3).join("-"),
              object: this.CurrentImageObject,
            });
          })
          .then(() => {
            //Get alert count from databae.
            return this.$store.dispatch("database/GetFromDatabase", {
              path: databasePathCount,
              returnSnapshotVal: true,
            });
          })
          .then((databaseWarningCount) => {
            //Push new warning count to database.
            this.$store.dispatch("database/SetOnDatabase", {
              path: databasePathCount,
              object: databaseWarningCount,
            });
          })
          .then(() => {
            this.IsConfirmButtonLoading = false;
            this.WarningApproval = false;
            this.alertSauron.details = "";
          })
          .then(() => {
            return this.$store.dispatch("database/SetOnDatabase", {
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/" +
                warningCam +
                "/date-index/" +
                warningDate,
              object: true,
            });
          })
          .then(() => {
            if (this.OperationHasSoundAlert && this.soundAlert) {
              let path;
              const onlyOneSpeaker = ["sabesp_cacapava", "sabesp_guararema"];
              const userActiveFlag =
                this.GetCurrentUser.activeFlag.toLowerCase();
              if (onlyOneSpeaker.includes(userActiveFlag)) {
                path = `sauron/${userActiveFlag}/camcontrol/alerta_sonoro`;
              } else {
                path = `sauron/${userActiveFlag}/camcontrol/${warningCam}/alerta_sonoro`;
              }

              this.$store.dispatch("database/SetOnDatabase", {
                path: path,
                object: firebase.database.ServerValue.increment(1),
              });
            }
          })
          .then(() => {
            this.soundAlert = true;
            this.GetAlertToWorkWith();
          });
      } else {
        return this.$store
          .dispatch("database/SetOnDatabase", {
            path:
              "sauron/human-disapproved/" +
              warningKey.split("-").slice(1, 3).join("-"),
            object: this.CurrentImageObject,
          })
          .then(() => {
            return this.$store.dispatch("database/RemoveFromDatabase", {
              path: databasePathRemove,
            });
          })
          .then(() => {
            this.IsConfirmButtonLoading = false;
          })
          .then(() => {
            this.soundAlert = true;
            this.GetAlertToWorkWith();
          });
      }
    },
    RetrieImageDownloadLink() {
      let warningDateString = new Date(this.CurrentImageObject.datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      this.RetrievingImageDownloadLink = true;
      this.$store
        .dispatch(
          "storage/GetMordorImageDownloadLink",
          this.GetCurrentUser.activeFlag.toLowerCase() +
            "-backlog/" +
            warningDateString +
            "/" +
            this.CurrentImageObject[".key"]
        )
        .then((url) => {
          this.ImageDownloadLink = url;
          this.IsConfirmButtonDisabled = false;
          this.IsConfirmButtonLoading = false;
          this.RetrievingImageDownloadLink = false;
        })
        .catch(() => {
          this.$store.dispatch(
            "sauron/removeImageFromList",
            this.CurrentImageObject[".key"],
            this.GetCurrentUser
          );
          this.RetrievingImageDownloadLink = false;
        });
    },
    ToggleWorking() {
      this.IsCurrentlyWorking = !this.IsCurrentlyWorking;
    },
    GetAlertToWorkWith() {
      if (this.FilteredAlerts.length < 1) {
        this.CurrentImageObject = null;
        this.ImageDownloadLink = "";
        return;
      }
      this.IsConfirmButtonDisabled = true;
      this.IsConfirmButtonLoading = true;
      this.CurrentImageObject =
        this.FilteredAlerts[this.FilteredAlerts.length - 1];
      this.RetrieImageDownloadLink();
    },
  },
  beforeDestroy() {
    this.$store.dispatch("sauron/unbindAvailableImages");
  },
  created() {
    this.$store.dispatch("sauron/bindAvailableImages", this.GetCurrentUser);
  },
};
</script>
