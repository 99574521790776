<template>
    <b-image :src="TemplateImageDownloadLink" alt="A random image" ratio="5by3"></b-image>
</template>
<script>
export default {
    name: 'SauronTemplateModal',
    props: {
        TemplateImageDownloadLink: String
    }
}
</script>