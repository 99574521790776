<template>
    <div>
        <b-field label-position="on-border">
            <template slot="label">
                <span class="has-text-primary is-family-sans-serif">Reporte para Whatsapp</span>
            </template>
            <textarea class="is-custom-height" ref="generatedReport" v-model="WhatsappReport"></textarea>
        </b-field>
        <div class="buttons">
            <b-button @click="CopyGeneratedReportToClipboard()">Copiar para área de transferência</b-button>
            <!-- <b-button type="is-info" outlined expanded @click="CopyGeneratedReportToClipboard()">Copiar para área de transferência</b-button> -->
            
            
        </div>
    </div>
</template>
<style lang="scss" scoped>
.is-custom-height {
    width: 100%;
    height: 25vh;
    font-size: larger;
}
</style>
<script>
export default {
    name: 'SauronAlertGenerator',
    props: {
        SauronAlertObject: Object,
        
    },
    data() {
        return {
            WhatsappReport: ''
        }
    },
    methods: {
        CopyGeneratedReportToClipboard() {
            this.$refs.generatedReport.select()
            document.execCommand('copy')
        },
        GenerateWhatsappReport(encoding) {       

               let weatherDataReport =
                    encoding + '*Data*: ' + this.SauronAlertObject.date+
                    encoding + '*Horario*: ' + this.SauronAlertObject.hours+
                    encoding + '*Posição*: ' + this.SauronAlertObject.position+                    
                    encoding + '*Camera*: ' + this.SauronAlertObject.camera+
                    encoding + '*Preset*: ' + this.SauronAlertObject.preset+                    
                    encoding + '*Latitude*: ' + this.SauronAlertObject.latitude+
                    encoding + '*Longitude*: ' + this.SauronAlertObject.longitude+
                    encoding + '*Google Maps*: '+ 'https://www.google.com/maps/search/?api=1&query=' + this.SauronAlertObject.latitude + ',' + this.SauronAlertObject.longitude+                                        
                    encoding + '*Operador*: ' + this.SauronAlertObject.operator+
                    encoding + '*Observações*: ' + this.SauronAlertObject.details                    

                    return weatherDataReport
                       
             
        },
        CheckIfTextNotNull(text) {
            if (!text) return 'Sem informações'
            else return text
        }
    },
    created() {
        this.WhatsappReport = this.GenerateWhatsappReport('\n')
    }
}
</script>